@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.wrapper {
  max-width: 580px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  padding: 1rem;
  margin: 0 auto;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.logo {
  height: 32px;
  margin: 20px;
  background: rgba(255, 255, 255, 0);
  
}

#components-layout-demo-fixed-sider {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}
.site-layout .site-layout-background {
  background: #fff;
}

/* 우상단 divider */
.ant-divider-vertical { 
  position: relative; 
  top: -0.06em;
  display: inline-block;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
  border-top: 0;
  border-left: 1px solid rgba(0, 0, 0, 1);
}

.ant-avatar.ant-avatar-icon {
  font-size: 18px;
  margin: '10px';
}

.ant-table-column-title {
  font-weight: 600;
}

/* .ant-btn {
  color: #40a9ff;
  border-color: #40a9ff;
  text-decoration: none;
} */

.ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #fafafa;
  width: '172px';
}

/* textarea */
textarea.ant-input {
  max-width: 100%;
  height: 100%;
  min-height: 100px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}

/* 대시보드 카드 */
.ant-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  background: #fff;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 8px;
}

tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}